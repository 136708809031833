import React, { useEffect } from 'react';
import hive from "./index"
import { project } from "./project/project"
import { useState } from "@hookstate/core"

const links: any = {
    "MI-36": "MI-36",
    "MI-35": "MI-35",
    "MI-34": "MI-34",
    "MI-33": "MI-33",
    "MI-32": "MI-32",
    "MI-31": "MI-31",
    "MI-30": "MI-30",
    "MI-29": "MI-29",
    "MI-28": "MI-28",
    "MI-27": "MI-27",
    "MI-26": "MI-26",
    "MI-25": "MI-25",
    "MI-24": "MI-24",
    "MI-23": "MI-23",
    "MI-22": "MI-22",
    "MI-21": "MI-21",
    "MI-20": "MI-20",
    "MI-19": "MI-19",
    "MI-18": "MI-18",
    "MI-17": "MI-17",
    "MI-08": "MI-08",
    "MI-07": "MI-07",
    "MI-06": "MI-06",
    "MI-05": "MI-05",
    "MI-04": "MI-04",
    "MI-03": "MI-03",
    "MI-02": "MI-02",
    "MI-01": "MI-01",
    "HI-12-R1": "HI-12",
    "HI-02-RDC": "HI-02",
    "HI-11-R1": "HI-11",
    "HI-01-RDC": "HI-01",
};

export function Embed(props: any) {
    return <div style={{
        width: "100%",
        height: "100%",
    }}>
        <div style={{
            width: "100%",
            height: "100%",
            overflow: "hidden"
        }}>
            {/* <iframe src={`https://www.theasys.io/viewer/${props.tourId}`} scrolling="no" allow="vr;gyroscope;accelerometer"
                style={{
                    width: '100%',
                    height: "100%",
                    border: "none"
                }}>
            </iframe> */}
            <iframe src={`https://www.realdesigner3d.fr/cloud/bouygues/demo/0003_${links[props.tourId]}`}
                style={{
                    width: '100%',
                    height: "100%",
                    border: "none"
                }}>
            </iframe>
        </div>
    </div>
}

function IframeEmbed() {
    const { width, height, mainColor, tour, activeScene } = useState(hive.state)

    return <div style={{
        background: "white",
        position: "absolute",
        height: height.get() + 'px',
        width: "100vw",
        zIndex: 2,
        display: "flex",
    }}>
        {/* {project.tourDisclaimer && hive.getSceneById(activeScene.get()).type && <>
            <div style={{
                position: "absolute",
                left: width.get() * 0.2,
                top: height.get() * 0.020,
                color: `#${mainColor.get()}`,
                fontSize: height.get() * 0.023,
            }}>
                {activeScene.get() && <>
                    Visite virtuelle d'un logement type T{hive.getSceneById(activeScene.get()).type}
                </>}
            </div>
        </>} */}
        <Embed tourId={activeScene.get()}></Embed>
    </div>
}

export default IframeEmbed;